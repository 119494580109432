import React, { useContext, useState, useEffect } from 'react';
import useScrollPosition from '@react-hook/window-scroll';
import { Link } from 'gatsby';
import { Context } from '../menu-state';

import './navigation.scss';
import ShareComponent from '../share';

interface NavigationProps {
  initiallyHidden: boolean
  homeTarget: string
  homeLabel: string
  homeState: any
}

const Navigation = ({
  initiallyHidden, homeTarget, homeLabel, homeState,
} : NavigationProps) => {
  const y = initiallyHidden ? useScrollPosition(5) : 0;
  const { setIsOpen } = useContext(Context);
  const [isReady, setIsReady] = useState(false);
  useEffect(() => setIsReady(true), []);
  const openMenu = () => setIsOpen(true);

  const isHidden = () => {
    if (isReady) {
      return initiallyHidden && y < window.innerHeight / 2;
    }
    return initiallyHidden;
  };
  return (
    <nav className={`navigation${
      isHidden() ? ' is-initially-hidden' : ''}`}
    >
      <ul className="navigation-link-list">
        <li className="navigation-link-list-item">
          <Link to={homeTarget} state={homeState}>{homeLabel}</Link>
        </li>
        {/* eslint-disable-next-line */}
        <li className="navigation-link-list-item" onClick={openMenu} role="button">
          Menu
        </li>
        <li className="navigation-link-list-item">
          <ShareComponent />
        </li>
        <li className="navigation-link-list-item">
          <Link to="/about">About</Link>
        </li>
      </ul>
    </nav>
  );
};

export default Navigation;
