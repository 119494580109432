import React from 'react';
import Navigation from '../navigation';

interface LayoutProps {
  children: React.ReactNode
  menuInitiallyHidden?: boolean
  homeTarget?: string
  homeLabel?: string
  homeState?: any
}

const Layout = ({
  children, menuInitiallyHidden, homeLabel, homeTarget, homeState,
} : LayoutProps) => (
  <>
    <Navigation
      initiallyHidden={menuInitiallyHidden}
      homeTarget={homeTarget}
      homeLabel={homeLabel}
      homeState={homeState}
    />
    <main>
      {children}
    </main>
  </>
);

Layout.defaultProps = {
  menuInitiallyHidden: false,
  homeTarget: '/',
  homeLabel: 'Home',
  homeState: null,
};

export default Layout;
