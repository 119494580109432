import React, { useState } from 'react';

import './share.scss';

const FILL = '#F5F0E9';
const XMLNS = 'http://www.w3.org/2000/svg';

const WINDOW_FEATURES = 'menubar=no,location=no,resizable=yes,scrollbars=yes,'
  + 'status=no,width=600,height=800';

const openWindow = (url: string) => {
  window.open(url, 'SHARE', WINDOW_FEATURES);
};

const openShareWindow = (network: string) => {
  const shareUrl = window.location.href;
  switch (network) {
    case 'facebook':
      openWindow(`//facebook.com/share.php?u=${shareUrl}`);
      break;
    case 'twitter':
      openWindow(`//twitter.com/intent/tweet?url=${shareUrl}`);
      break;
    default:
      break;
  }
};

const ShareComponent = () => {
  const [isVisible, setIsVisible] = useState(false);
  return (
    <div className="share-component">
      <button
        type="button"
        className="share-component-button"
        onClick={() => setIsVisible(!isVisible)}
      >
        Share

      </button>
      <ul className={`share-component-list${isVisible ? ' is-visible' : ''}`}>
        <li>
          <button
            onClick={() => openShareWindow('facebook')}
            type="button"
            className="share-component-button"
            title="Share on Facebook"
          >
            <svg
              xmlns={XMLNS}
              fill="none"
              viewBox="0 0 15 28"
              height="24"
            >
              <path
                fill={FILL}
                d="M14.1.2v4.4h-2.5c-1 0-1.6.2-2 .5-.3.4-.4
              1-.4 1.8V10H14l-.7 5H9.2v12.4h-5V15H0V10h4.2V6.5c0-2.1.5-3.7
              1.7-4.8A6.1 6.1 0 0 1 10.4 0c1.6 0 2.8 0 3.7.2Z"
              />
            </svg>

          </button>
        </li>
        <li>
          <button
            onClick={() => openShareWindow('twitter')}
            type="button"
            className="share-component-button"
            title="Share on Twitter"
          >
            <svg xmlns={XMLNS} width="24" height="24">
              <path
                d="M24 4.6c-.9.3-1.8.6-2.8.7a5 5 0 002.1-2.7c-1 .6-2 1-3
              1.2a5 5 0 00-8.5 4.5A14 14 0 011.7 3a5 5 0 001.5 6.6c-.8
              0-1.6-.2-2.2-.6A5 5 0 005 14a5 5 0 01-2.3 0 5 5 0 004.6 3.5 9.9
              9.9 0 01-7.3 2A14 14 0 0021.5 7.1 10 10 0 0024 4.6z"
                fill={FILL}
              />
            </svg>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default ShareComponent;
